<div [style]="!fromPopup ? 'padding: 20px;' : ''">
  <nz-card
    [nzTitle]="rfqTitle"
    [nzExtra]="bidsAddButton"
    class="scroll-cards-body"
  >
    <nz-tabset nzType="card">
      <nz-tab nzTitle="Active Bid ({{ activeBidsList.length }})">
        <nz-table
          class="bids-on-rfq-table scroll1250"
          #activeBid
          [nzData]="activeBidsList"
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th>Vendor</th>
              <th>Bid Price & Fees</th>
              <th>Status</th>
              <th *ngIf="awardedBid">PO Status</th>
              <th>Chat with Vendor</th>
              <th>Convert And Submit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of activeBid.data ; let i = index"
              [style.background-color]="
                item.bidStatus === 3 || item.bidStatus === 5
                  ? '#89e8c1'
                  : item.bidStatus === 6
                  ? '#ff8f8f'
                  : item.bidStatus === 4 &&
                    item.resubmissionRequestedBy === 'owner'
                  ? 'orange'
                  : '#fff'
              "
            >
              <td>
                <p>
                  Vendor:
                  <nz-tag
                     nz-popover

                    (click)="vendorDetails(i,'ACTIVE')"
                    style="cursor: pointer"
                  >
                    {{ item.vendorCompany.companyName }}</nz-tag
                  >
                </p>
                <p>
                  Contact Name:
                  {{ item.vendorCompany.owner.fullName | titlecase }}
                </p>
                <p>Contact Email: {{ item.vendorCompany.owner.email }}</p>
              </td>
              <td>
                <p>
                  Total Bid Price: {{ item.invoiceCurrency }}
                  {{ item.totalBidValue + item.extraActivityTotalPriceWithDiscountTax | number : "1.2-2" }}
                </p>
                <p>Attender Fees: {{ item.spoolifyFees }}%</p>
              </td>
              <td>
                Vendor Status:
                <nz-tag> {{ getBidStatusForVendor(item) }}</nz-tag
                ><br />

                Owner Status:
                <nz-tag>
                  {{
                    rfqDetails.jobStatus > 9
                      ? poDetails.bid && poDetails.bid._id === item.ownerBid
                        ? statusService.jobStatus[rfqDetails.jobStatus]
                        : "N/A"
                      : poDetails.bid &&
                        poDetails.bid._id === item.ownerBid &&
                        poDetails.poStatus === 4
                      ? statusService.poStatus[poDetails.poStatus]
                      : statusService.jobStatus[rfqDetails.jobStatus]
                  }}</nz-tag
                >
              </td>
              <td *ngIf="awardedBid">
                <nz-tag
                  style="cursor: pointer"
                  (click)="closePopup.emit()"
                  [routerLink]="['/main/manage-job/po-process/' + rfqId]"
                  >{{ awardedBid === item._id ? "Awarded" : "" }}</nz-tag
                >
              </td>
              <td [style.padding]="'40px'">
                <nz-badge
                  [nzCount]="
                    vendorUnreadCounts[
                      'rfq_' +
                        rfqDetails._id +
                        '_vendor_' +
                        item.vendorCompany._id
                    ]
                  "
                >
                  <button
                    nz-button
                    nzType="primary"
                    class="line1"
                    (click)="
                      updateChatLoadingForbid(item);
                      chatWithVendor(item.vendorCompany)
                    "
                    [nzLoading]="item.chatLoading"
                  >
                    <i nz-icon nzType="message"></i>
                  </button>
                </nz-badge>
              </td>
              <td>
                <button
                  nz-button
                  nzType="primary"
                  (click)="closePopup.emit()"
                  [routerLink]="['/main/manage-job/bids-details/', item._id]"
                >
                  Open Bid
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="Bids Awaited({{ awaitedBidsList.length }})">
        <nz-table
          class="scroll1250"
          #bidsAwaited
          [nzData]="awaitedBidsList"
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th>Vendor name</th>
              <th>Vendor Status</th>
              <th>Vendor Contact</th>
              <th>Bid Status</th>
              <th>Chat with Vendor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of bidsAwaited.data; let i = index">
              <td><nz-tag nzType="default"

                (click)="vendorDetails(i,'AWAITED')"
                style="cursor: pointer">{{item.companyName }}</nz-tag> </td>

                <td>
                <nz-tag [nzColor]="item.vendorStatus === 5 ? 'green': 'orange'">{{
                  item.vendorStatus === 5
                  ? 'Live'
                  : item.auditRequired ? 'Audit pending' : 'Profile incomplete' }}
                </nz-tag>
                </td>
              <td>
                {{ item.owner.fullName | titlecase }}
                <br />
                {{ item.owner.email }}
              </td>
              <td>Awaiting Bid</td>
              <td>
                <nz-badge
                  [nzCount]="
                    vendorUnreadCounts[
                      'rfq_' + rfqDetails._id + '_vendor_' + item._id
                    ]
                  "
                >
                  <button
                    nz-button
                    nzType="primary"
                    (click)="
                      updateChatLoadingForbid(item); chatWithVendor(item)
                    "
                    [nzLoading]="item.chatLoading"
                  >
                    Chat with Vendor
                  </button>
                </nz-badge>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="No Bids({{ noBidsList.length }})">
        <nz-table
          #noBids
          [nzData]="noBidsList"
          [nzShowPagination]="false"
          class="scroll1250"
        >
          <thead>
            <tr>
              <th>Vendor name</th>
              <th>Vendor contact</th>
              <th>No Bid Reason</th>
              <th>Chat with Vendors</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of noBids.data; let i =index">
              <td><nz-tag nzType="default"

                (click)="vendorDetails(i,'NO_BID')"
                style="cursor: pointer">{{ item.vendorCompany?.companyName }}</nz-tag></td>
              <td>{{ item.vendorCompany.owner.email }}</td>
              <td>{{ item.noBidReason }}</td>
              <td>
                <nz-badge
                  [nzCount]="
                    vendorUnreadCounts[
                      'rfq_' +
                        rfqDetails._id +
                        '_vendor_' +
                        item.vendorCompany._id
                    ]
                  "
                >
                  <button
                    nz-button
                    nzType="primary"
                    (click)="
                      updateChatLoadingForbid(item);
                      chatWithVendor(item.vendorCompany)
                    "
                    [nzLoading]="item.chatLoading"
                  >
                    Chat with Vendor
                  </button>
                </nz-badge>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
    </nz-tabset>
  </nz-card>

  <ng-template #rfqTitle>
    <i
      *ngIf="!fromPopup"
      nz-icon
      nzType="left"
      nzTheme="outline"
      class="backArrow"
      (click)="backToPreviousPage()"
    ></i
    >Bids On RFQ
    <span style="padding: 0px 5px; color: red">[{{ rfqDetails?.rfqNo }}]</span>
  </ng-template>
  <ng-template #bidsAddButton>
    <button
      style="margin-right: 5px"
      nz-button
      nzType="primary"
      nzDanger
      (click)="openAddVendorModal()"
      [nzLoading]="chatLoading"
      *ngIf="!fromPopup"
    >
      Add More Vendors
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="updateChatLoading(); chatWithOwner()"
      [nzLoading]="chatLoading"
      *ngIf="!fromPopup"
    >
      Chat With Owner
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <div style="height:50%;width:500px">

      <app-quick-vendor-detail [vendordetails]="selectedBid"></app-quick-vendor-detail>
      <!-- <app-quick-vendor-detail [vendordetails]="getVendorList()"></app-quick-vendor-detail> -->

    </div>
  </ng-template>
</div>
