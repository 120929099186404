export const adminMenu = [
  {
    key: "dashboard",
    title: "Dashboard",
    route: "main/dashboard",
    icon: "dashboard",
    children: [],
  },
  {
    key: "vendors",
    title: "Vendors",
    route: "main/vendors",
    icon: "robot",
    children: [],
  },
  {
    key: "owners",
    title: "Owners",
    route: "main/owners",
    icon: "solution",
    children: [],
  },
  // {
  //   key: 'demo-requests',
  //   title: 'Demo Requests',
  //   route: 'main/demo-requests',
  //   icon: 'database',
  //   children: []
  // },
  {
    key: "tariff-management",
    title: "Tariff Management",
    route: "main/manage-job/tariff-manager",
    icon: "reconciliation",
    children: [],
  },
  {
    key: "service-management",
    title: "Service Management",
    route: "main/serviceManagement",
    icon: "reconciliation",
    children: [],
  },
  {
    key: "equipment-management",
    title: "Equipment Management",
    route: "main/equipmentManagement",
    icon: "setting",
    children: [],
  },
  {
    key: "brand-management",
    title: "Brand management",
    route: "main/brandManagement",
    icon: "border-outer",
    children: [],
  },
  {
    key: "bulk-upload",
    title: "Bulk Upload",
    route: "/main/bulk-upload/upload",
    icon: "border-outer",
    children: [],
  },
  {
    key: "email-template",
    title: "Communication",
    route: "#",
    icon: "ant-design",
    children: [
      {
        key: "email-template",
        title: "Email Template",
        route: "main/email-template",
        icon: "ant-design",
        children: [],
      },
      {
        key: "notification-template",
        title: "Notification Template",
        route: "main/notification-template",
        icon: "ant-design",
        children: [],
      },
    ],
  },
  {
    key: "manage-jobs",
    title: "Manage Jobs",
    route: "#",
    icon: "ant-design",
    children: [
      {
        key: "all-jobs",
        title: "All Jobs",
        route: "main/manage-job/job-list",
        icon: "ant-design",
        children: [],
      },
      {
        key: "job-payments",
        title: "Job Payments",
        route: "main/manage-job/payments",
        icon: "ant-design",
        children: [],
      },
      {
        key:  "requested-jobs",
        title: "Jobs Initiated",
        route:  "main/manage-job/request-jobs",
        icon:  "ant-design",
        children: [],
      }
    ],
  },
  {
    key: "chats",
    title: "Chats",
    route: "main/chats",
    icon: "message",
    children: [],
  },
  {
    key: "currency-exchange",
    title: "Currency Exchange",
    route: "main/currencyExchange",
    icon: "stock",
    children: [],
  },
  {
    key: "admin-users",
    title: "Administrator",
    route: "main/admin-users",
    icon: "user",
    children: [
      {
        key: "admin-management",
        title: "Users",
        route: "main/admin-users/admin-management",
        icon: "user",
        children: [],
      },
      {
        key: "admin-group",
        title: "Groups",
        route: "main/admin-users/admin-groups",
        icon: "user",
        children: [],
      },
      {
        key: "partner",
        title: "Partners",
        route: "main/admin-users/partners",
        icon: "user",
        children: [],
      },
      {
        key: "owner-vendor-users",
        title: "Owner / Vendor Users",
        route: "main/admin-users/owner-vendor-users",
        icon: "user",
        children: [],
      },
    ],
  },
  {
    key: "job-bid-invoice",
    title: "Job-Bid-Invoice",
    route: "main/three-way-table-match",
    icon: "deployment-unit",
    children: [],
  },
];
